import { graphql, navigate } from "gatsby";
import React, { useEffect } from "react";
import { HomePageLayout } from "../components/layouts/HomePageLayout/HomePageLayout";
import { PageTitle, SEO } from "../modules/seo";

const HomePage = () => {
  useEffect(() => {
    navigate("/");
  }, []);
  return (
    <>
      <SEO title={PageTitle.Home} />
      <HomePageLayout />
    </>
  );
};

export default HomePage;
